import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css']
})
export class CalenderComponent implements OnInit {

  constructor() { }dateValue:any;
  ngOnChanges() {
    this.isRequired = this.data.Isrequired;
    this.dateValue=this.data.DateValue;

    if(this.dateValue != null && this.dateValue != "0001-01-01T00:00:00"){
     
      this.dateControl.setValue(this.dateValue);

    }
  }
  ngOnInit() {
  }
  isRequired:boolean=false;
  dateControl = new FormControl();
  @Input() data: any;
  
  @Output() valueChange = new EventEmitter();dateAfterOffset:Date;
  ValueChange(event: MatDatepickerInputEvent<Date>) {
 event.value.setMinutes((event.value.getTimezoneOffset() * -1));

  this.valueChange.emit(event.value);
  }

}
