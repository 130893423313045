import { Component, OnInit } from '@angular/core';import { DashboardService } from '../dashboard/dashboard.service';
@Component({
  selector: 'app-sidenav', templateUrl: './sidenav.component.html', styleUrls: ['./sidenav.component.css']
}) export class SidenavComponent implements OnInit {
  token: string; count: any;
  statusCode: any; church: string;
  constructor(private apiService: DashboardService) { } ngOnInit() {
    this.token = sessionStorage.getItem("Token"); this.church = sessionStorage.getItem("ChurchName");
 this.getCount();
  } isExpanded = false; collapse() { this.isExpanded = false; }
  getCount() {
    this.apiService.getCountOfPages(this.token)
      .subscribe(Response => {
        this.count = Response;
       
      },
        errorCode => this.statusCode = errorCode
      );
  }

}
