import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../appSettings';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  myAppUrl: string = ""; malankaraCommonApi: string = "";
  constructor(private http: HttpClient) {
    this.myAppUrl = AppSettings.API_ENDPOINT;
    this.malankaraCommonApi = AppSettings.MalankaraCommonApi;
  }
  DeleteAgapeFormByID(ID, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/DeleteAgapeFormByID",      ID, httpOptions);
 }
  DeletePremaritalFormByID(ID, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/DeletePremaritalFormByID",      ID, httpOptions);
 }
   UpdateOfficeDetailAgapeForm(formID, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/UpdateOfficeDetailAgpeForm",      formID, httpOptions);
 }
  getPremaritalFormByID(formID, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/GetPremaritalFormByID",      formID, httpOptions);
 }
  getAgapeFormByID(formID, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/GetAgapeFormByID",      formID, httpOptions);
 }getAllAgapeForms(token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {};
     return this.http.post(this.myAppUrl + "api/getAllAgapeFormReqs", obj,
      httpOptions);
  }
  getAllPremaritalForms(token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {};
     return this.http.post(this.myAppUrl + "api/getAllPremaritalForms", obj,
      httpOptions);
  }
  
  searchCp(searchText, churchName) {
    var obj = { "SearchText": searchText, "church": churchName };


    return this.http.post(this.myAppUrl + "api/SearchContentPage",
      obj);

  }

  getContentPageByID(pageGUID, token) {

    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {
      "PageGUID": pageGUID
    };
    return this.http.post(this.myAppUrl + "api/getPageByPageGUID",
      obj, httpOptions);

  }

  getPriestDetailById(id) {
    var obj = { "Id": id };
    return this.http.post(this.myAppUrl + "api/getPriestByID", obj);
  }
  getParisDetailById(id) {
    var obj = { "Id": id };
    return this.http.post(this.myAppUrl + "api/getParishByID", obj);
  }
  getFaqByID(pageGUID, token) {

    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {
      "Id": pageGUID
    };
    return this.http.post(this.myAppUrl + "api/getFAQById",
      obj, httpOptions);

  }
  getCountOfPages(token) {

    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }

    return this.http.get(this.myAppUrl + "api/getCountOfPages", httpOptions);

  }

  addParish(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/CreateParishList",
      content, httpOptions);
  }
  addPriest(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/CreatePriestList",
      content, httpOptions);
  }


  tokrn: string;
  addContent(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/CreatePage",
      content, httpOptions);
  }
  addFAQ(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/AddFAQ",
      content, httpOptions);
  }

  EditContent(content, token,church) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var apiEndPoint = "";
    var api = "";
    if (church == "SGIOCAdelaide") {
      api = "EditContentPage_Adelaide";
      apiEndPoint = AppSettings.MalankaraCommonApi;
    } else {
      api = "EditContentPage";
      apiEndPoint = this.myAppUrl;
    }
    return this.http.post(apiEndPoint + "api/" + api,
      content, httpOptions);
  }
  EditParish(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/EditParish",
      content, httpOptions);
  }
  EditPriest(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/EditPriest",
      content, httpOptions);
  }
  EditFAQ(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/EditFAQ",
      content, httpOptions);
  }

 DeleteContent(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
   return this.http.post(this.myAppUrl + "api/DeleteContentPage",
      content, httpOptions);
  }
  DeletePriest(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/DeletePriest",
      content, httpOptions);
  }
  DeleteParish(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/DeleteParish",
      content, httpOptions);
  }
  delteFAQ(content, token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    return this.http.post(this.myAppUrl + "api/DeleteFAQ",
      content, httpOptions);
  }
  
  uploadImage(fileToUpload: File) {
    let formData: FormData = new FormData();
    formData.append('UploadImage', fileToUpload);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');


    return this.http.post(this.myAppUrl + "api/UploadFile", formData, { responseType: 'json' })

  }getContentPages(token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {};
    return this.http.post(this.myAppUrl + "api/getAllPage",obj,
      httpOptions);
  }
  getParishList(token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {};
    return this.http.post(this.myAppUrl + "api/getAllParish", obj,
      httpOptions);
  }
  getPriestList(token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {};
    return this.http.post(this.myAppUrl + "api/listAllPriest", obj,
      httpOptions);
  }

  
 getTop20ContentPages(token) {
  this.tokrn = token;
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    }),
  }
  var obj = {};
   return this.http.post(this.myAppUrl + "api/getTop20Page", obj,
    httpOptions);
}

  getAllFAQs(token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {};
    return this.http.post(this.myAppUrl + "api/getAllFAQs", obj,
      httpOptions);
  }

  delteImageFromAzure(imageURl,token) {
   
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {};


    return this.http.post(this.myAppUrl + "api/DeleteImage", imageURl, httpOptions);

  }
  uploadBase64ImageUrl(imageURl, token,church) {


    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
   
    var apiEndPoint = "";
    var api = "";
    if (church == "SGIOCAdelaide") {
      api = "uploadBase64Image_SGIOCAdelaide";
      apiEndPoint = AppSettings.MalankaraCommonApi;
    } else {
      api = "uploadBase64Image";
      apiEndPoint = this.myAppUrl;
    }
    return this.http.post(apiEndPoint + "api/" + api, imageURl, httpOptions);

  } 
  searchPriestByNAme(searchText) {


    return this.http.post(this.myAppUrl + "api/SearchPriestByName", searchText);

  } 

  getAllCategory(token) {
    this.tokrn = token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
    }
    var obj = {};
    return this.http.post(this.myAppUrl + "api/getAllCategory", obj,
      httpOptions);  }


}

