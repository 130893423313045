import { Component, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  isDashboard: boolean; token: string; firstName: string;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private router: Router) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)'); this.isDashboard = false;
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  } church: string; loggedIn: boolean; isParumala: boolean; isGregorianTv: boolean; LogoUrl: string;
  ngOnInit() {
    this.token = sessionStorage.getItem("Token"); this.openedSidenav = true;
    if (this.token != null) {
      this.loggedIn = true;
      var decodedToken = this.parseJwt(this.token);
      this.firstName = decodedToken.given_name; sessionStorage.setItem("currentUser", this.firstName);
      this.church = decodedToken.Church; if (this.church == "ParumalaSeminary") {
        this.isParumala = true;
        this.LogoUrl = "https://parumalachurch.blob.core.windows.net/parumalacontainer/WhiteLogo.png";
      } else if (this.church == "GregorianTV") {
          this.isGregorianTv = true; this.LogoUrl = "https://sgiocadelaide.blob.core.windows.net/gregoriantvcontainer/GregorianTvLogo.png";
      } else if (this.church == "PampadyDayara") {
          this.LogoUrl = "https://sgiocadelaide.blob.core.windows.net/pampadydayaracontainer/pampady.png";
      } else if (this.church == "Velankanni") {
        this.LogoUrl = "https://sgiocadelaide.blob.core.windows.net/velankannicontainer/velankanni.jpg";
      }
      else if (this.church == "StGems") {
        this.LogoUrl = "https://sgiocadelaide.blob.core.windows.net/stgemscontainer/logo.png";
      } else if (this.church == "SGIOCBrisbane") {
        this.LogoUrl = "https://sgiocadelaide.blob.core.windows.net/sgiocbrisbane/Search-Icon.png";
      }else if(this.church == "MadrasDiocese"){
        this.LogoUrl="https://sgiocadelaide.blob.core.windows.net/madrasdiocese/Dashbord-Logo.png";
      }
      sessionStorage.setItem("ChurchName", this.church);
    } else { this.router.navigate(['']); this.loggedIn = false; }
  }
  opened: boolean; openedSidenav: boolean;
  toggleSidenav() {
    if (this.openedSidenav) { this.openedSidenav = false } else { this.openedSidenav = true }
  }
  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }; navigateTo(value) {
    if (value == 'logout') {
      sessionStorage.clear(); window.location.reload();
    } return false;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}


