import { BrowserModule } from '@angular/platform-browser'; import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module'; import { AppComponent } from './app.component';
import { MaterialModule } from 'src/app/MaterialModule'; import { FlexLayoutModule } from "@angular/flex-layout";
import { SidenavComponent } from './sidenav/sidenav.component';
import { ReusableComponentsModule } from './reusable-components/reusable-components.module';
@NgModule({
  declarations: [AppComponent, SidenavComponent], imports: [BrowserModule, FlexLayoutModule, MaterialModule, HttpClientModule, BrowserAnimationsModule,
    AppRoutingModule,
    ReusableComponentsModule  ],  providers: [],  bootstrap: [AppComponent]})export class AppModule { }
